<template>
    <div class="coupon-card-get-log-container">
        <cell-v3 class="receive-user" :hide-icon="true" :hide-action="true">
            <div slot="title" class="user-info">
                <div>{{expressInfo.real_name}}</div>
                <div class="mobile">{{expressInfo.mobile}}</div>
            </div>
        </cell-v3>
        <cell-v3 class="express-info border-bottom" :hide-icon="true" :hide-actions="true">
            <div slot="title" class="title">
                <div class="zitidian">{{expressInfo.province}} {{expressInfo.city}} {{expressInfo.town}} {{expressInfo.address}}</div>
            </div>
        </cell-v3>
        <div class="goods-list">
            <div class="goods-header">{{cardName}}</div>
            <div v-for="(item,index) in finalDataList" :key="index">
                <coupon-goods-v3
                        :class="[index < (finalDataList.length-1) ? 'border-bottom' : '']"
                        :img_url="item.cover"
                        :description="item.title"
                        :text="'价值'+item.market_price+'元'"
                        :options="item.options"
                        :selectOptions="item.selectOption"
                        @onSelect="onSelect(index)"
                        class="goods"
                        :showExpress="true"
                        :express_fee="item.shipping_fee"
                        showText="快递配送"
                        :showExpressLog="true"
                        :expressId="item.express_id"
                >
                    <div class="goods-extend">
                        <div class="goods-extend-line">
                            <div class="label">领取时间</div>
                            <div class="time">{{item.create_time}}</div>
                        </div>
                        <div class="goods-extend-line">
                            <div class="label">领取数量</div>
                            <div class="time">{{item.number}}</div>
                        </div>
                        <div class="goods-extend-line">
                            <div class="label">订单备注</div>
                            <div class="time">{{item.remark}}</div>
                        </div>
                        <!--<div class="goods-extend-line">-->
                        <!--<div class="warning">提示：每月领取一次共计5次,剩余4次</div>-->
                        <!--</div>-->
                    </div>
                </coupon-goods-v3>
            </div>
        </div>
    </div>
</template>

<script>
  import CellV3 from '@/template/community/cell/cellV3'
  import CouponGoodsV3 from '@/template/coupon-card/couponGoodsV3'

  export default {
    name: 'couponCardGetLogV2',
    components: {CouponGoodsV3, CellV3},
    props: {
      lists: {
        type: Array,
        default () {
          return []
        },
      },
      expressInfo: {
        type: Object,
        default: () => {
          return {}
        }
      },
      cardName: {
        type: String,
        default: ''
      }
    },
    mounted () {
      //计算标题可以使用的长度
      this.$props.lists.forEach(item => {
        item.checked = false
        item.selectOption = 0
        this.finalDataList.push(item)
      })
      this.$forceUpdate()
    },
    data () {
      return {
        finalDataList: []
      }
    }
  }
</script>

<style scoped lang="scss">
    .coupon-card-get-log-container {
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;

        .receive-user {
            display: flex;
            align-items: center;
            padding-bottom: 12px;

            .user-info {
                font-size: 14px;
                font-weight: bold;
                color: #333;
                display: flex;
                align-items: center;

                .mobile {
                    display: flex;
                    align-items: center;
                    color: #939393;
                    font-weight: 100;
                    margin-left: 7px;
                }
            }
        }

        .site-point {
            display: flex;
            align-items: center;
            padding-bottom: 8px;
            padding-top: 12px;

            .title {
                flex: 1;
                display: flex;
                align-items: center;

                .zitidian {
                    font-size: 14px;
                    font-weight: bold;
                    color: #333;
                }

                .site-point-name {
                    color: #333333;
                    font-size: 14px;
                }
            }

        }

        .address {
            padding-bottom: 12px;
            .title {
                padding-left: 56px;
                font-size: 12px;
                color: #939393;
                line-height: 18px;
            }
        }

        .goods-list {
            padding: 15px 0 0 0;

            .goods-header {
                font-size: 14px;
                color: #333333;
                font-weight: bold;
                margin-bottom: 14px;
            }

            .goods {

                .goods-extend {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 15px 0 10px 0;

                    .goods-extend-line {
                        width: 100%;
                        display: flex;
                        margin-bottom: 10px;

                        .label {
                            width: 109px;
                            min-width: 109px;
                            max-width: 109px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            color: #333333;
                            font-size: 12px;
                            margin-right: 12px;
                        }

                        .time {
                            color: #333333;
                            font-size: 12px;
                            word-wrap: break-word;
                            word-break: break-all;
                        }

                        .select-class {
                            flex: 1;
                            height: 20px;
                            -webkit-border-radius: 4px;
                            -moz-border-radius: 4px;
                            border-radius: 4px;
                        }

                        .warning {
                            width: 100%;
                            color: #DF8621;
                            font-size: 12px;
                            background: #FFF9EC;
                            height: 29px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 0 15px;
                            -webkit-border-radius: 4px;
                            -moz-border-radius: 4px;
                            border-radius: 4px;
                        }

                        /deep/ .nut-textinput {
                            flex: 1 !important;
                        }

                        /deep/ .nut-textinput input {
                            border: none;
                            color: #939393;
                            padding: 0 !important;
                            height: 24px;
                            font-size: 12px;
                            font-weight: 100;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .border-bottom {
        border-bottom: #F6f6f6 solid 1px;
    }

    .express-info {
        margin-top: -5px;
        padding: 0 0 15px 0;
        .title {
            font-size: 12px;
            color: #A2A2A2;
        }
    }
</style>