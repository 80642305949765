<template>
    <div class="card-get-log-container">
        <div class="coupon-card-detail-container">
            <rsck-refresh ref="refresh" @onPullDown="onPullDown" @onPullUp="onPullUp">
                <template v-for="(item,index) in dataList">
                    <coupon-card-get-log-v0 :card-name="item.card_name" :lists="item.card_info"
                                            v-if="item.express_type_id == 0"
                                            style="margin-bottom:10px"></coupon-card-get-log-v0>
                    <coupon-card-get-log-v1 :sitePointInfo="item.siteInfo" :lists="item.card_info"
                                            v-if="item.express_type_id == 1"
                                            style="margin-bottom:10px"></coupon-card-get-log-v1>
                    <coupon-card-get-log-v2 :expressInfo="item.express_info" style="margin-bottom:10px"
                                            :lists="item.card_info" v-if="item.express_type_id == 2"
                                            :showExpress="true"></coupon-card-get-log-v2>
                </template>
            </rsck-refresh>
        </div>
    </div>
</template>

<script>
  import CouponCardGetLogV1 from '@/template/coupon-card/coupon-card-get-log-V1'
  import CouponCardGetV2 from '@/template/coupon-card/coupon-card-get-V2'
  import CouponCardGetLogV2 from '@/template/coupon-card/coupon-card-get-log-V2'
  import CouponCardGetLogV0 from '@/template/coupon-card/coupon-card-get-log-V0'
  // import MiniRefresh from 'minirefresh';
  import 'minirefresh/dist/debug/minirefresh.css'
  import RsckRefresh from '@/components/common/rsck-refresh'

  export default {
    name: 'card-get-log',
    components: {RsckRefresh, CouponCardGetLogV0, CouponCardGetLogV2, CouponCardGetV2, CouponCardGetLogV1},
    mounted () {
      document.title = '领取记录'
      let query = this.$route.query
      this.card_id = query.card_id || 0
      this.goods_id = query.goods_id || 0
      this.scrollHeight = window.innerHeight
      this.getCardUseLog()
    },
    data () {
      return {
        card_id: 0,
        page: 1,
        page_size: 10,
        dataList: [],
        scrollHeight: 0,
        hasMore: true,
        isLoading: false,
        endDown: false,
        goods_id: 0
      }
    },
    methods: {
      onPullDown () {
        console.log(this.$refs)
        this.getCardUseLog()
        this.$refs.refresh.endPullDown()
      },
      onPullUp () {
        this.$refs.refresh.endPullUp()
      },
      async getCardUseLog () {
        if (!this.isLoading) {
          this.isLoading = true
          await this.$api.couponCard.getCardUseLog({
            card_id: this.card_id,
            page: this.page,
            goods_id: this.goods_id,
            page_size: this.page_size
          }).then(response => {
            response.data.forEach(item => {
              let cardInfo = {
                title: item.title,
                goods_id: item.goods_id,
                cover: item.cover_path,
                number: item.number,
                remark: item.remark,
                market_price: item.market_price,
                pick_up_code_url: item.pick_up_code_url,
                pick_up_code: item.pick_up_code,
                create_time: item.create_time,
                shipping_fee: item.shipping_fee,
                express_id: item.id
              }
              item.card_info = [cardInfo]
              if (item.express_type_id == 1) {
                item.siteInfo = {
                  ...item.siteInfo,
                  real_name: item.realname,
                  real_mobile: item.mobile
                }
              } else if (item.express_type_id == 2) {
                if (typeof item.addInfo.id == 'undefined') {
                  item.express_info = {}
                } else {
                  item.express_info = item.addInfo
                }
              }

              this.dataList.push(item)
            })
            this.page++
            if (response.data.length < this.page_size) {
              this.hasMore = false
            }
            this.isLoading = false
          })
        }

      }
    }

  }
</script>

<style scoped lang="scss">
    .card-get-log-container {
        width: 100vw;
        min-height: 100vh;
        background: #f6f6f6;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .minirefresh-scroll {
            background: #f6f6f6;
            position: relative;
            transform: scale(1);
        }

        /deep/ .minirefresh-theme-default .minirefresh-upwrap {
            min-height: 0 !important;
            height: 0 !important;
            padding: 0 !important;
        }
    }
</style>