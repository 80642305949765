<template>
    <div class="coupon-card-get-log-container">
        <cell-v3 class="receive-user border-bottom" :hide-icon="true" :hide-actions="true">
            <div slot="title" class="user-info">
                <div>提货人：{{sitePointInfo.real_name}}</div>
                <div class="mobile">{{sitePointInfo.real_mobile}}</div>
            </div>
        </cell-v3>
        <cell-v3 class="site-point" :hide-icon="true" :hide-actions="true">
            <div slot="title" class="title">
                <div class="zitidian">自提点：</div>
                <div class="site-point-name">{{sitePointInfo.site_name}}</div>
            </div>
        </cell-v3>
        <cell-v3 :hide-icon="true" :hide-actions="true" class="address border-bottom">
            <div slot="title" class="title">
                {{sitePointInfo.address}}
            </div>
        </cell-v3>
        <div class="goods-list">
            <div class="goods-header">{{cardName}}</div>
            <div v-for="(item,index) in finalDataList" :key="index">
                <coupon-goods-v3
                        :class="[index < (finalDataList.length-1) ? 'border-bottom' : '']"
                        :img_url="item.cover"
                        :description="item.title"
                        :text="'价值'+item.market_price+'元'"
                        showText="自提点提货"
                        @onSelect="onSelect(index)"
                        class="goods"
                        :showExpressLog="true"
                        :expressId="item.express_id"
                >
                    <div class="goods-extend">
                        <div class="goods-extend-line">
                            <div class="label">领取时间</div>
                            <div class="time">{{item.create_time}}</div>
                        </div>
                        <div class="goods-extend-line">
                            <div class="label">领取数量</div>
                            <div class="time">{{item.number}}</div>
                        </div>
                        <div class="goods-extend-line">
                            <div class="label">提货码</div>
                            <div class="actions" @click="showQrcode(item.pick_up_code,item.pick_up_code_url)">
                                核销码
                                <img src="https://api.mengjingloulan.com/storage/uploads/20211230/cd58cad125526c78c00fc6b51ec04fab.png"/>
                            </div>
                        </div>
                        <div class="goods-extend-line">
                            <div class="label">订单备注</div>
                            <div class="time">{{item.remark}}</div>
                        </div>
                        <!--<div class="goods-extend-line">-->
                            <!--<div class="warning">提示：每月领取一次共计5次,剩余4次</div>-->
                        <!--</div>-->
                    </div>
                </coupon-goods-v3>

            </div>
        </div>

        <nut-popup
                v-model="showVisible"
                :closeable="true"
                :round="true"
                :close-on-click-overlay="false"
                position="bottom"
        >
            <div class="popup-window">
                <div class="title">请出示如下二维码给自提点员工进行核销</div>
                <div class="img">
                    <img :src="pick_up_code_url"/>
                </div>
                <div class="warning">或提供 <span class="code">{{pick_up_code}}</span> 给自提点员工</div>
            </div>
        </nut-popup>

    </div>
</template>

<script>
  import CellV3 from '@/template/community/cell/cellV3'
  import CouponGoodsV3 from '@/template/coupon-card/couponGoodsV3'

  export default {
    name: 'couponCardGetLogV1',
    components: {CouponGoodsV3, CellV3},
    props: {
      lists: {
        type: Array,
        default: () => {
          return []
        },
      },
      sitePointInfo: {
        type: Object,
        default: () => {
          return {}
        }
      },
      cardName: {
        type: String,
        default: ''
      }
    },
    mounted () {
      //计算标题可以使用的长度
      this.$props.lists.forEach(item => {
        item.checked = false
        item.selectOption = 0
        this.finalDataList.push(item)
      })
      this.$forceUpdate()
    },
    data () {
      return {
        finalDataList: [],
          showVisible:false,
          pick_up_code_url:'',
          pick_up_code:'',

      }
    },
      methods: {
          showQrcode:function(pick_up_code,pick_up_code_url) {
              this.pick_up_code_url=pick_up_code_url
              this.pick_up_code=pick_up_code
              //展示提货码
              this.showVisible = true
              console.log(pick_up_code,pick_up_code_url)
          },
      }
  }
</script>

<style scoped lang="scss">
    .coupon-card-get-log-container {
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;

        .receive-user {
            display: flex;
            align-items: center;
            padding-bottom: 12px;

            .user-info {
                font-size: 14px;
                font-weight: bold;
                color: #333;
                display: flex;
                align-items: center;

                .mobile {
                    display: flex;
                    align-items: center;
                    color: #939393;
                    font-weight: 100;
                    margin-left: 7px;
                }
            }
        }

        .site-point {
            display: flex;
            align-items: center;
            padding-bottom: 8px;
            padding-top: 12px;

            .title {
                flex: 1;
                display: flex;
                align-items: center;

                .zitidian {
                    font-size: 14px;
                    font-weight: bold;
                    color: #333;
                }

                .site-point-name {
                    color: #333333;
                    font-size: 14px;
                }
            }

        }

        .address {
            padding-bottom: 12px;
            .title {
                padding-left: 56px;
                font-size: 12px;
                color: #939393;
                line-height: 18px;
            }
        }

        .goods-list {

            .goods-header {
                font-size: 14px;
                color: #333333;
                font-weight: bold;
                margin-bottom: 14px;
            }

            .goods {

                .goods-extend {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 15px 0 10px 0;

                    .goods-extend-line {
                        width: 100%;
                        display: flex;
                        margin-bottom: 10px;

                        .label {
                            width: 109px;
                            min-width: 109px;
                            max-width: 109px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            color: #333333;
                            font-size: 12px;
                            margin-right: 12px;
                        }

                        .time {
                            color: #333333;
                            font-size: 12px;
                            word-wrap: break-word;
                            word-break: break-all;
                        }

                        .select-class {
                            flex: 1;
                            height: 20px;
                            -webkit-border-radius: 4px;
                            -moz-border-radius: 4px;
                            border-radius: 4px;
                        }

                        .warning {
                            width: 100%;
                            color: #DF8621;
                            font-size: 12px;
                            background: #FFF9EC;
                            height: 29px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 0 15px;
                            -webkit-border-radius: 4px;
                            -moz-border-radius: 4px;
                            border-radius: 4px;
                        }

                        /deep/ .nut-textinput {
                            flex: 1 !important;
                        }

                        /deep/ .nut-textinput input {
                            border: none;
                            color: #939393;
                            padding: 0 !important;
                            height: 24px;
                            font-size: 12px;
                            font-weight: 100;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .border-bottom {
        border-bottom: #F6f6f6 solid 1px;
    }
    .actions{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        border: #F6f6f6 solid 0px;
        color: #F46A17;
        width: 100%;
        display:flex;
        text-align: right;
        align-items: center;
        flex-direction:row-reverse;
        justify-content: right;
        img{
            width: 14px;
            height:14px;
        }
    }
    .popup-window {
        padding: 50px;
        margin:auto;
        postion: flex;
        bottom:20%;
        flex-wrap: wrap;
        width: 70%;

        .title {
            width: 100%;
            font-size: 14px;
            color: #333;
        }

        .img{
            width: 100%;

            img{
                width: 100%;

            }
        }

        .warning{
            width: 100%;
            font-size: 14px;
            color: #333;
            align-items: center;

            .code{

                color: #108ee9;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
</style>