<template>
    <div class="goods">
        <div class="goods-info">
            <img class="img" :src="img_url"/>
            <div class="content">
                <div class="description">{{ description }}</div>
                <div class="rule">
                    <div class="select-container">
                        <span>{{showText}}</span>
                    </div>
                    <div class="express-fee" v-if="showExpress">快递 ¥{{express_fee}}</div>
                </div>

                <div class="text">
                    <div class="text-container">{{ text }}</div>
                    <div class="button-container" @click="viewExpressLog" v-if="showExpressLog">
                        <div class="button">查看物流</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="goods-extends">
            <slot></slot>
        </div>

    </div>
</template>

<script>
  import RsckSelect from '@/components/common/rsck-select'
  import RsckRadio from '@/components/common/rsck-radio'

  export default {
    name: 'CouponGoodsV3',
    components: {RsckRadio, RsckSelect},
    props: {
      img_url: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
      options: {
        type: Array,
        default: () => {
          return [
            {label: '选项一', value: 0},
            {label: '选项一', value: 1},
            {label: '选项二', value: 2},
          ]
        }
      },
      selectOptions: {
        type: [String, Number],
        default: 0
      },
      showText: {
        type: String,
        default: '自提点取货'
      },
      showExpressLog: {
        type: Boolean,
        default: false
      },
      showExpress: {
        type: Boolean,
        default: false
      },
      express_fee: {
        type: [String, Number],
        default: 0
      },
      expressId: {
        type: [String, Number],
        default: 0
      }
    },
    mounted () {
      this.getType = this.$props.selectOptions
    },
    data () {
      return {
        show: false,
      }
    },
    methods: {
      onRuleClick () {
        this.$emit('onRuleClick', this.$props.param)
      },
      onSelect () {
        this.$emit('onSelect')
      },
      viewExpressLog () {
        let params = {
          id: this.$props.expressId
        }
        let options = encodeURIComponent(JSON.stringify(params))
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url: '/web/couponCard/card-express-info?query=' + options,
        })
      }
    },
  }
</script>

<style scoped lang="scss">
    .goods {
        display: flex;
        flex: 1;
        flex-wrap: wrap;

        .goods-info {
            width: 100%;
            display: flex;

            .img {
                width: 109px;
                height: 90px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                border-radius: 8px;
                margin-right: 12px;
            }

            .content {
                flex: 1;
                flex-direction: row;
                flex-wrap: wrap;
                display: flex;

                .description {
                    height: 15px;
                    font-size: 14px;
                    color: #333333;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    word-break: break-all;
                }

                .rule {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .select-container {
                        flex: 1;

                        span {
                            color: #333333;
                            font-size: 12px;
                            background: #F6F6F6;
                            -webkit-border-radius: 4px;
                            -moz-border-radius: 4px;
                            border-radius: 4px;
                            padding: 3px 5px;
                        }

                    }

                    .express-fee {
                        color: #333333;
                        font-size: 12px;
                    }
                }

                .warning {
                    height: 24px;
                    color: #F46A17;
                    font-size: 12px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                }

                .text {
                    width: 100%;
                    font-size: 14px;
                    color: #C10000;
                    font-weight: bold;
                    display: flex;
                    align-items: flex-end;

                    .text-container {
                        flex: 1;
                    }

                    .button-container {
                        width: 82px;

                        .button {
                            width: 82px;
                            height: 36px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #F46A17;
                            font-size: 12px;
                            font-weight: 100;
                            border: 1px solid #F46A17;
                            -webkit-border-radius: 18px;
                            -moz-border-radius: 18px;
                            border-radius: 18px;
                        }
                    }
                }
            }
        }

        .goods-extends {
            width: 100%;
        }

        .popup-window {
            padding: 30px;
        }

        .radio-line {
            width: 100%;
            margin-bottom: 10px;
        }

        .radio-text {
            color: #333;
            font-size: 12px;
        }
    }
</style>